import React from 'react';
import './About.css';

function About() {
    return (
        <div>
            <div className="centralize-about">
                <h2>CorLAS<sup>TM</sup> 3.3</h2>
                Copyright 1999-2020 DNV GL AS
            </div>
            <div className="left-about">
                <p>
                    CorLAS<sup>TM</sup> is a tool to help evaluate the integrity and remaining corrosion life of
                    pressurized pipes and vessels that contain local corrosion or cracking.  The evaluation
                    procedures are based on information and material properties data that are available in
                    the published literature as is discussed and cited in the accompanying manual.  The user
                    should be familiar with the use and limitations of that information and those data before
                    using CorLAS<sup>TM</sup>.  The information contained herein is advisory only and the use of the
                    materials and methods is solely at the risk of the user.
                </p>
                <p>
                    The distribution and use of CorLAS<sup>TM</sup> is governed by the terms and conditions within the
                    license agreement between DNV GL USA, Inc. and the Licensee.
                    Please refer to the license agreement for information regarding software ownership/copyright,
                    termination of the license, warranty, limitation of liability, confidentiality, and other
                    restrictions. Licensee may not copy the written materials accompanying CorLAS<sup>TM</sup> except
                    (a) with DNV GL USA's express, prior written permission, and (b) for Licensee's own internal
                    training purposes only.
                </p>
                <h3>CAUTIONARY NOTE</h3>
                <p>
                    FOR THE MATERIAL PROPERTIES AND ANALYTICAL PROCEDURES THAT ARE INCORPORATED IN CorLAS<sup>TM</sup>,
                    CorLAS<sup>TM</sup> ESTIMATES SAFE PRESSURES, CRITICAL FLAW SIZES, AND REMAINING CORROSION LIVES FOR
                    PRESSURIZED PIPES AND VESSELS.  THE USER SHOULD UNDERSTAND THE MODELING METHODS USED IN
                    CorLAS<sup>TM</sup> AND THE PRINCIPLES OF INELASTIC FRACTURE MECHANICS BEFORE ADDING NEW MATERIALS
                    DATA TO CorLAS<sup>TM</sup>.
                </p>
                <p>
                    NO WARRANTY OF MERCHANTABILITY OR FITNESS, NOR ANY OTHER EXPRESS OR IMPLIED WARRANTY, IS
                    MADE WITH RESPECT TO THIS PRODUCT.
                </p>
            </div>
            <div className="credits">
                CorLAS, Copyright 1999-2020, was developed by<br />
                Carl E. Jaske, DNV GL USA, Inc.<br />
                Version 3.3 update David J. Stucki<br />
                5777 Frantz Road, Dublin, Ohio 43017-1386 USA<br />
                TEL/FAX: (614) 761-1214/1633<br />
                E-MAIL: CorLAS@dnvgl.com
            </div>
        </div>
    )
};

export default About;