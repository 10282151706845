import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Landing from '../Landing';
import { Switch, Route, Redirect } from 'react-router-dom';
import './UnauthenticatedApp.css';

function UnauthenticatedApp() {
  return (
    <div>
      <Header />
      <Landing />
      <Switch>
          <Route path="/">
            <Redirect to="/" />
          </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default UnauthenticatedApp;
