import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Home.css';

function Home() {
    const [userName, setUserName] = useState("");
    const [emailAdd, setEmailAdd] = useState("");
    const [error, setError] = useState(false);

    function getUserName() {
        return axios.get('/account/UserName').then(function (response) {
            var res = response.data.split(",");
            if (res.length === 2) {
                return (res[1].trim() + " " +  res[0].trim());
            }
        })
            .catch(function (error) {
                // handle error
                console.log(error.response);
            });
    }

    function getEmail() {
        return axios.get('/account/Email');
    }

    useEffect(() => {
        axios.all([getUserName(), getEmail()])
            .then(axios.spread(function (userName, emailAdd) {
                // Both requests are now complete
                setUserName(userName);
                setEmailAdd(emailAdd.data);
            }))
            .catch(function (error) {
                // handle error
                setError(true);
                console.log(error.response);
            });

        axios.get('/', { withCredentials: true })
            .then(function (response) {
                //console.log(response)
            })
            .catch(function (error) {
                // handle error
                console.log("Oops: " + error.response);
            })
    }, []);

    return (
        <div>
            {error ? (
                <div>
                    There seems to be an error.
                </div>
            ) : (
                <div>
                    <div className="centralize-home">
                            <h2>Welcome, <span className="green-home">{userName}</span>, to DNV GL's CorLAS<sup>TM</sup> App on Veracity!</h2>
                    </div>
                    <div className="left-home">
                            The email address you have used to sign in is: <span className="blue-home">{emailAdd}</span>. When you are finished using 
                                the app, please log out from the menu in the upper right corner by clicking your initials.
                    </div>
                    <div className="centralize-home">
                            <img src="/assets/images/banner.jpg" alt="CorLAS 3.3" />
                    </div>
                </div>
                )}
        </div>
    )
};
        
export default Home;