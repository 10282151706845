import React from 'react';
import './FileDownload.css';

function buildHeader() {
    var t = "CorLAS 3.3 Web App Report\n\n";

    t += 'This Report has been generated from information or data provided by the Customer and\n';
    t += 'is intended for use only by Customer employees possessing requisite expertise in its\n';
    t += 'subject matter. DNV GL shall not be responsible in any way in connection with erroneous\n';
    t += 'information or data provided to it by the Customer or for the effects of any such\n';
    t += 'erroneous information or data whether or not contained or referred to in this Report.\n\n';

    t += 'Assumptions used for the following results:\n';
    t += 'Flaw Depth Profile = semi-eliptical\n';
    t += 'Flow Constant = 0.5\n';
    t += 'Design Safety Factor = 0.72\n';
    t += 'Hoop Stress Y Factor = thin wall (OD) formula for hoop stress\n';
    t += 'Flow Strength Formula = ys + 10k (Flow Stress); 0.5*(ys+ts) (Fracture)\n';
    t += 'Based on J Fracture Toughness Criterion\n\n';

    return t + window.myAppData.header;
}

function buildCSV() {
    var r = buildHeader();
    for (var i = 0; i < window.myAppData.rows.length; i++) {
        r += '\n' + window.myAppData.rows[i]
    }
    return r;
}

class FileDownload extends React.Component {
    handleDownload = (e) => {
        //console.log('Downloading...')
        var results = [buildCSV()];
        var blob = new Blob(results, { type: 'text/csv;charset=utf-8' });
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'results.csv';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    render() {
        return <button className="downloadbutton"
            onClick={this.handleDownload}>Download Results
                 </button>
    }
}

export default FileDownload;