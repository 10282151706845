import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import csv2json from 'csvjson-csv2json';
import './FileDialog.css';
import CorLASDataTable from "../CorLASDataTable";
import CorLASDataErrors from '../CorLASDataErrors';

function invalidData(jdata) {
    var err = [];
    for (var li in jdata) {
        var row = parseInt(li) + 1;
        if (jdata[li].ys < 1.0 || jdata[li].ys > 147500.0) {
            err.push('Line ' + row + ': Yield strength must be between 1 and 147,500 psi.');
        }
        if (jdata[li].ts <= jdata[li].ys || jdata[li].ts > 999999.0) {
            err.push('Line ' + row + ': Tensile strength must be between YS and 999,999 psi.');
        }
        if (jdata[li].ys / jdata[li].ts < 0.5 || jdata[li].ys / jdata[li].ts >= 1.0) {
            err.push('Line ' + row + ': The yield-to-ultimate strength ratio must be between 0.5 and 1.0 to use correlation for calculating the strain hardening exponent.');
        }
        if (jdata[li].cvn < 1.0 || jdata[li].cvn > 125.0) {
            err.push('Line ' + row + ': The Charpy impact energy must be between 1 and 125 ft-lb to use correlation.');
        }
        if (jdata[li].od <= 2.0 * jdata[li].wt || jdata[li].od > 9999.99) {
            err.push('Line ' + row + ': OD must be between 2*WT and 9999.99 in.');
        }
        if (jdata[li].wt < jdata[li].dep || jdata[li].wt >= 0.5 * jdata[li].od) {
            err.push('Line ' + row + ': WT must be between maximum flaw depth and OD/2.');
        }
        if (jdata[li].loc !== "I" && jdata[li].loc !== "O") {
            err.push('Line ' + row + ': Flaw location must be inside or outside.');
        }
        if (jdata[li].len <= 2 * jdata[li].wt || jdata[li].len > 3.0 * Math.PI * jdata[li].od) {
            err.push('Line ' + row + ': Flaw length must be between 2 * WT and 3 * circumference.');
        }
        if (jdata[li].dep <= 0.0 || jdata[li].dep >= jdata[li].wt) {
            err.push('Line ' + row + ': Flaw depth must be between 0.0 and WT.');
        }
    }
    if (err.length > 0) {
        return err;
    } else {
        return false;
    }
}

function buildFileSelector() {
    window.myAppData = {};
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.onchange = e => {
        var f = e.target.files[0];
        var r = new FileReader();
        r.readAsText(f, 'UTF-8');
        r.onload = readerEvent => {
            var content = readerEvent.target.result;
            content = content.slice(content.indexOf("\n")+1); //skip past header rows
            content = content.slice(content.indexOf("\n") + 1);
            try {
                var jsondata = csv2json("ys,ts,wt,od,cvn,len,dep,loc\n" + content, { parseNumbers: true });
            } catch (excpt) {
                ReactDOM.render(
                    <div>The file you selected was not a properly formatted csv file. See the Info tab for instructions.</div>,
                    document.getElementById('data')
                );
                return fileSelector;
            }
            var errs = invalidData(jsondata);
            if (errs) {
                ReactDOM.render(
                    <CorLASDataErrors
                        lines={errs}
                    />,
                    document.getElementById('data')
                );
            }
            else {
                axios.post('/api/v1.0/CorLASData/CorlasUpload',
                    jsondata,
                    { headers: { 'Content-Type': 'application/json' } }
                ).then(response => {
                    ReactDOM.render(
                        <CorLASDataTable
                            lines={response}
                        />,
                        document.getElementById('data')
                    );
                });
            }
            document.getElementById('downloadresults').hidden = '';
        }
    }
    return fileSelector;
}

class FileDialog extends React.Component {
    componentDidMount() {
        this.fileSelector = buildFileSelector();
    }

    handleFileSelect = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    render() {
        return <button className="dialogbutton"
            onClick={this.handleFileSelect}>Select file
             </button>
    }
}
        
export default FileDialog;
