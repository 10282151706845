import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from "../../Views/Home";
import Info from "../../Views/Info";
import Analysis from "../../Views/Analysis";
//import ApiCall from "../../Views/ApiCall";
import About from "../../Views/About";
//import VeracityApiCall from "../../Views/VeracityApiCall";
import './Routes.css';

function Routes() {
    return (
        <div className="centralize">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/info" component={Info} />
                <Route path="/analysis" component={Analysis} />
                <Route path="/about" component={About} />
            </Switch>
        </div>
    )
}

export default Routes;

//<Route path="/apicall" component={ApiCall} />
//<Route path="/vapicall" component={VeracityApiCall} />
