import React from 'react';
import './CorLASDataTable.css';
import DataTable from "../DataTable";

class CorLASDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: props.lines,
            headings: [],
            rows: []
        };
        this.state.headings.push('Yield Strength');
        this.state.headings.push('Tensile Strength');
        this.state.headings.push('Wall Thickness');
        this.state.headings.push('Outside Diameter');
        this.state.headings.push('CVN');
        this.state.headings.push('Flaw Length');
        this.state.headings.push('Flaw Depth');
        this.state.headings.push('Flaw Location');
        this.state.headings.push('Failure Pressure (Flow Stress)');
        this.state.headings.push('Failure Pressure (Fracture)');
        window.myAppData.header = this.state.headings[0];
        for (var i = 1; i < this.state.headings.length; i++) {
            window.myAppData.header += ',' + this.state.headings[i].trim();
        }
        for (var j = 0; j < this.state.lines.data.length; j++) {
            var ldata = [];
            ldata.push(this.state.lines.data[j].ys);
            ldata.push(this.state.lines.data[j].ts);
            ldata.push(this.state.lines.data[j].wt);
            ldata.push(this.state.lines.data[j].od);
            ldata.push(this.state.lines.data[j].cvn);
            ldata.push(this.state.lines.data[j].len);
            ldata.push(this.state.lines.data[j].dep);
            ldata.push(this.state.lines.data[j].loc);
            ldata.push(Math.trunc(this.state.lines.data[j].fpflow * 100) / 100);
            ldata.push(Math.trunc(this.state.lines.data[j].fpfrac * 100) / 100);
            this.state.rows.push(ldata);
        }
        window.myAppData.rows = this.state.rows;
        this.state.displayRows = Math.min(10, this.state.rows.length);
    }

    render() {
        var message;
        if (this.state.displayRows === this.state.rows.length) {
            message = 'Here are all the rows of output. Click the Download Results button to retrieve them.';
        } else {
            message = 'Here are the first ' + this.state.displayRows + ' rows of output. Click the Download Results button to retrieve all output.';
        }
        return (
            <div>
                <div>{message}</div>
                <DataTable headings={this.state.headings} rows={this.state.rows.slice(0, this.state.displayRows)} />
            </div>
        );
    }
}
        
export default CorLASDataTable;