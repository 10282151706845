import React from 'react';
import './Analysis.css';
import FileDialog from "../../CorLASApp/FileDialog";
import FileDownload from "../../CorLASApp/FileDownload";

function Analysis() {
    return (
        <div>
            <div className="row">
                <div id="selectfile">
                    <FileDialog />
                </div>
                <div id="downloadresults" hidden={true}>
                    <FileDownload />
                </div>
            </div>
            <div className="left-analysis" id="data"></div>
        </div>
    )
};

export default Analysis;