import React from 'react';
import './Info.css';

function Info() {
    return (
        <div>
            <div className="centralize-info">
                <h2 className="list-info">Instructions</h2>
                <p>In order to use this app, you will need the CorLAS<sup>TM</sup> Data Template, which can be downloaded below. The data template
                    is a comma-separated-values (csv) formatted file, that must be formatted according to the following guidelines in order to 
                    achieve results (see the CorLAS<sup>TM</sup> User Manual, which can be downloaded below, for definitions and more info). &nbsp;
                    <em>All numeric values must be positive</em></p>
                <table class="Table">
                    <thead>
                        <tr>
                            <th class="Cell Cell-header">Input Parameter</th>
                            <th class="Cell Cell-header">Data Requirements</th>
                            <th class="Cell Cell-header">Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="Cell">Yield Strength</td>
                            <td class="Cell">1.0 &le; Ys &le; 147,500 psi</td>
                            <td class="Cell">the tensile yield strength (psi), use <b>minimum</b> for conservative results</td>
                        </tr>
                        <tr>
                            <td class="Cell">Tensile Strength</td>
                            <td class="Cell">Ys &lt; Ts &le; 999999.0 <br/> 0.5 &le; Ys/Ts &le; 1.0</td>
                            <td class="Cell">the tensile ultimate strength (psi), use <b>minimum</b> for conservative results</td>
                        </tr>
                        <tr>
                            <td class="Cell">Wall Thickness</td>
                            <td class="Cell">Depth &le; WT &lt; OD/2</td>
                            <td class="Cell">the WT of the pipe (inches), use <b>minimum</b> for conservative results</td>
                        </tr>
                        <tr>
                            <td class="Cell">Outside Diameter</td>
                            <td class="Cell">2 * WT &lt; OD &le; 9999.99</td>
                            <td class="Cell">the OD of the pipe (inches), use <b>maximum</b> for conservative results</td>
                        </tr>
                        <tr>
                            <td class="Cell">CVN</td>
                            <td class="Cell">1.0 &le; CVN &le; 125.0</td>
                            <td class="Cell">the Charpy V-notch impact energy (ft-lb)</td>
                        </tr>
                        <tr>
                            <td class="Cell">Flaw Length</td>
                            <td class="Cell">2 * WT &lt; Length &lt; 3 * &pi; * OD</td>
                            <td class="Cell"><b>maximum</b> length of flaw in the axial direction (inches)</td>
                        </tr>
                        <tr>
                            <td class="Cell">Flaw Depth</td>
                            <td class="Cell">0 &lt; Depth &le; WT</td>
                            <td class="Cell"><b>maximum</b> depth of flaw (inches)</td>
                        </tr>
                        <tr>
                            <td class="Cell">Flaw Location</td>
                            <td class="Cell">I for inside, O for outside</td>
                            <td class="Cell">which surface of the pipe</td>
                        </tr>
                    </tbody>
                </table>
                <h2 className="list-info">Downloads</h2>
                <a href="/assets/CorLAS_DataTemplate.csv">CorLAS<sup>TM</sup> Data Template</a>
                <br />
                <a href="/assets/CorLAS App Manual.pdf" target="_blank">CorLAS<sup>TM</sup> User Manual</a>
                <h2 className="list-info">Version History</h2>
                <table class="Table">
                    <thead>
                        <tr>
                            <th class="Cell Cell-header">Version</th>
                            <th class="Cell Cell-header">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="Cell">1.0</td>
                            <td class="Cell">Initial Release</td>
                        </tr>
                        <tr>
                            <td class="Cell">2.0</td>
                            <td class="Cell">Model updated; see <a href="https://www.prci.org/Research/All_Final_Reports/18515.aspx">https://www.prci.org/Research/All_Final_Reports/18515.aspx</a></td>
                        </tr>
                        <tr>
                            <td class="Cell">3.0</td>
                            <td class="Cell">Internal to DNV GL re-write of application</td>
                        </tr>
                        <tr>
                            <td class="Cell">3.3</td>
                            <td class="Cell">Veracity Web Application release</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default Info;
