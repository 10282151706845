import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';
import Dropdown from 'react-bootstrap/Dropdown'
import { NavLink } from "react-router-dom";
import { AuthConsumer } from '../../Context/AuthContext';

function Header() {
    const [initials, setInitials] = useState("");
    const appName = "CorLAS 3.3"

    useEffect(() => {
        axios.get('/account/UserName', { withCredentials: true })
            .then(function (response) {
                var res = response.data.split(",");
                if (res.length === 2) {
                    setInitials(res[1].trim().charAt(0).toUpperCase() + res[0].trim().charAt(0).toUpperCase());
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error.response);
            })
    }, []);

    return (
        <header className="o-header">
            <AuthConsumer>
                {({ isSignedIn }) => (
                    <nav className="o-header-nav header-position large">
                        <NavLink className="o-header-link" to="/" exact>
                            <img className="logo-image" src="/assets/images/logo/logo-standard-rgb.svg" alt="DNV GL Logo" />
                        </NavLink>
                        {isSignedIn ? (
                            <div className="o-header-right">
                                <a href="/" className="o-header-product">
                                    {appName}
                                </a>
                                <ul className="o-header-actions">
                                    <li className="o-header-dropdown o-header-dropdown-user">
                                        <div className="o-header-user">
                                            <Dropdown>
                                                <Dropdown.Toggle className="o-header-user">
                                                    {initials}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu alignRight={true}>
                                                    <Dropdown.Item href="https://services.veracity.com/EditProfile">Settings</Dropdown.Item>
                                                    <Dropdown.Item href="/account/SignOut">Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                                <div className="o-header-right ">
                                    <a href="/" className="o-header-product">
                                        {appName}
                                    </a>
                                    <ul className="o-header-actions">
                                        <li className="o-header-action">
                                            <a target="_self" href="/account/SignIn" className="o-header-action-btn">
                                                Login
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        <button
                            type="button"
                            aria-label="Toggle navigation"
                            aria-expanded="false"
                            className="o-header-mobile-btn"
                        //   onClick="toggleMenu()"
                        >
                            <span className="o-header-mobile-btn-icon"></span>
                        </button>
                    </nav>
                )}
            </AuthConsumer>
        </header>
    )
}

export default Header;