import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Routes from '../Routes';
import Navbar from '../Navbar';

function AuthenticatedApp() {
  return (
    <div>
      <Header />
      <Navbar />
      <Routes />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default AuthenticatedApp;