import React from 'react';
import './Navbar.css';
import { NavLink } from "react-router-dom";

function Navbar() {
    return (
        <div>
            <ul className="o-header-links">
                <li>
                    <button aria-label="Close menu" className="o-header-dropdown-close">
                        <i className="fal fa-times"></i>
                    </button>
                </li>
                <li>&nbsp;&nbsp;</li>
                <li id="Home" className="o-header-item">
                    <NavLink className="o-header-link" to="/" exact>Home</NavLink>
                </li>
                <li id="Info" className="o-header-item">
                    <NavLink className="o-header-link" to="/info">Info</NavLink>
                </li>
               <li id="Analysis" className="o-header-item">
                    <NavLink className="o-header-link" to="/analysis">Analysis</NavLink>
                </li>
                <li id="About" className="o-header-item">
                    <NavLink className="o-header-link" to="/about">About</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;

//<li id="apicall" className="o-header-item">
//    <NavLink className="o-header-link" to="/apicall">ApiCall</NavLink>
//</li>
