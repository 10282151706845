import React from 'react';
import './Landing.css';
import { AuthConsumer } from '../../Context/AuthContext';

function Landing() {
    return (
        <div>
            <AuthConsumer>
                {({ isSignedIn }) => (
                    <div>
                        {isSignedIn ? (
                        <div className="welcome-text">
                            <h1>Welcome back</h1>
                        </div>
                        ) : (
                        <div className="welcome-text">
                            <h1>CorLAS<sup>TM</sup> 3.3</h1>
                        </div>
                        )}
                        <img className="image" src="/assets/images/Plant-ecosystem.jpg" alt="background" />
                    </div>

                )}
            </AuthConsumer>
        </div>
    )
}

export default Landing;