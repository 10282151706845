import React from 'react';
import './CorLASDataErrors.css';
import DataTable from "../DataTable";

class CorLASDataErrors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: props.lines,
            headings: [],
            rows: []
        };
        this.state.headings.push('Data Errors');
        window.myAppData.header = this.state.headings[0];
        for (var j = 0; j < this.state.lines.length; j++) {
            var ldata = [];
            ldata.push(this.state.lines[j]);
            this.state.rows.push(ldata);
        }
        window.myAppData.rows = this.state.rows;
        this.state.displayRows = Math.min(10, this.state.rows.length);
    }

    render() {
        var message;
        if (this.state.displayRows === this.state.rows.length) {
            message = 'Here are all the errors. Click the Download Results button to retrieve them.';
        } else {
            message = 'Here are the first ' + this.state.displayRows + ' errors. Click the Download Results button to retrieve all errors.';
        }
    return (
            <div>
                <div>
                    <p>Please consult the Info tab for data validation rules.</p>
                <p>{message}</p>
                </div>
                <DataTable headings={this.state.headings} rows={this.state.rows.slice(0, this.state.displayRows)} />
            </div>
        );
    }
}
        
export default CorLASDataErrors;