import React from 'react';
import AuthenticatedApp from './Components/AuthenticatedApp';
import UnauthenticatedApp from './Components/UnauthenticatedApp';
import { AuthConsumer } from './Context/AuthContext';

function App() {
  return (
    <AuthConsumer>
      {({ isSignedIn }) => (
        isSignedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />
      )}
    </AuthConsumer>
  )
}

export default App;